@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Code+Latin:wght@100..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.ml-10{
  margin-left: 10% !important;
}
.mr-10{
  margin-right: 10% !important;
}
.m-10{
  margin: 10% !important;
}
.m-5{
  margin: 5% !important;
}

.font-size{
  font-size: 30px;
}
.font-size-20{
  font-size:20px
}

 .wpo-project-single-main-img {
  margin-bottom: 30px;
}
 .wpo-project-single-main-img .thumbnail {
  max-height: 430px;
  overflow: hidden;
}
 .wpo-project-single-main-img .thumbnail img {
  width: 100%;
  max-width: 100%;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: all 3s;
}
 .wpo-project-single-main-img .thumbnail:hover img {
  -webkit-transform: translateY(calc(-100% + 430px));
}

.custom-placeholder::placeholder {
  color: rgba(255, 255, 255, 0.68) !important; /* Set the desired color and opacity */
}

/* Add custom styles for M PLUS Code Latin font */
.m-plus-code-latin-light {
  font-family: "M PLUS Code Latin", monospace;
  font-weight: 100;
}

.m-plus-code-latin-regular {
  font-family: "M PLUS Code Latin", monospace;
  font-weight: 400;
}

.m-plus-code-latin-bold {
  font-family: "M PLUS Code Latin", monospace;
  font-weight: 700;
}
.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}


body {
  font-family: "Lato", sans-serif;
  background-image: url('../src/assets/images/bg-tablet-pattern.svg');
  background-repeat: no-repeat;
  background-size: 800px;
  background-position: 90% -25%;
}

#cta {
  background-image: url('./assets/images/bg-simplify-section-desktop.svg');
  background-repeat: no-repeat;
}

@media (max-width: 576px) {
  .res-ml-0{
    margin-left: 0px !important;
  }
  .res-mrl-10{
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .res-max-w-lg {
    max-width: 32rem/* 512px */;
  }
  .res-max-w-100 {
    width: 100% !important;/* 512px */;
  }
  .res-max-w-50 {
    width: 50% !important;/* 512px */;
  }
  .res-p-4{
    padding: 15px;
  }
  body {
    background-position: 50px -50px;
    background-size: 500px;
  }
  .community-text{
    font-size: 40px;
    padding-bottom: 50px;
  }
  .cummunity-join-text-div{
    padding-right: 9px;
    padding-left: 9px;
    padding-bottom: 10px;
  }
  .community-icon{
    padding-top: 20px;
    padding-bottom: 160px;
    width:100px;
    height:20px;
  }
  .cummunity-subscribe{
    width: 320px;
    padding-bottom: 30px;
  }
  .cummunity-subscribe-placeholder{
    width:200px;
    font-size: 16px;
  }
  .cummunity-subscribe-subs-button{
    font-size: 16px;
    padding: 7px;
  }
  .footer-icon{
    width: 200px;
  }


  .footer-content-div {
    display: flex;
    flex-direction: column;
  }
  .service-div-div{
    background-color: black;
    padding-left: 20px;
    /*padding-left: 50px;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*background-color: black;*/
    /*align-items: center;*/
  }

  .footer-services {
    align-items: center;
    font-size: 20px;
  }

  .footer-service-list {
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
  .footer-social-media{
    /*background-color: black;*/
    padding-right: 200px;
    display: flex;
    /*flex-direction: row;*/
    /*align-items: left;*/
    font-size: 20px;

  }
  .socil-media-text{
    font-size: 20px;
    padding-right: 200px;

  }
  .social-list{
    padding: 0px;
    display: grid;
    grid-template-columns: 200px 200px;

    grid-column-gap: 7px;
    grid-row-gap: 10px;
  }
  .res-m-3{
    margin: 3% !important;
  }
  .res-m-5{
    margin: 5% !important;
  }
  .res-m-10{
    margin-left: 5% !important;
    margin-right: 8% !important;
  }
  .res-w-100{
    width: 100%;
  }






  #cta {
    background-image: url('./assets/images/bg-simplify-section-mobile.svg');
  }
}

/* Hamburger Menu */
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

/* custom input icon */

.custom-date-input {
  position: relative;
  background: url('./assets/images/date.png') no-repeat right 10px center;
  background-size: 20px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(217, 217, 217, 0.5);
}

[contenteditable="true"]:focus {
  outline: none;
}


.custom-date-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  cursor: pointer;
}


.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

/* CSS code */
.schedule-button {
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #0EA288;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  border-radius: 20px;
  /* Rounded corners */
  transition: background-color 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* Smooth transitions */
  font-family: 'Poppins', sans-serif;
  /* Change font to Poppins */
}

.schedule-button:hover {
  background-color: #0c0c0c;
  transform: translateX(-5px);
  /* Move button to the left */
}

.schedule-button .arrow {
  display: none;
  width: 20px;
  height: 20px;
  fill: #ffffff;
  opacity: 0;
  /* Initial opacity */
  transform: translateX(-20px) rotate(-45deg);
  /* Initial position */
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* Smooth transitions */
}

.schedule-button:hover .arrow {
  display: inline-block;
  opacity: 1;
  /* Fade in on hover */
  animation: slide-in 0.4s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(-50px) rotate(-45deg);
    opacity: 0.1;
    /* Slightly visible */
  }

  to {
    transform: translateX(0) rotate(-45deg);
    opacity: 1;
    /* Fully visible */
  }
}


.element {
  /* top-left top-right/bottom-left bottom-right */
  border-radius: 5px  0px 0px 120px;

}

.btn{
  background: linear-gradient(90deg, #0EA288 0%, #2DFFDA 100%);

}
.testimonial-section-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.butt{
  /* Group 24 */

  position: absolute;
  width: 201px;
  height: 54px;
  left: 654px;
  top: 414px;


}

.toppper{
  z-index: 100;
}

.btnh:hover{
  /* Component 16 */

  background: #0EA288;
  box-shadow: 0px 4px 22px 0px #0000001F;



}

#specifyColor{
  accent-color: #0EA288;
}



/* Custom styles for the select element */
.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('./assets/images/dropdown.png') ;
  background-repeat: no-repeat;
  background-position: right 4px center;
  padding-right: 20px;
  border: 1px solid #0EA288;
  /* padding: 8px 12px;  */
  cursor: pointer;
}

/* Style for when the select is open (optional) */
.custom-select:focus {
  outline: none;
}

/* schedule */
.schedule-icon-button {
  background-color: #0EA288;
  color: #FFFFFF;
  border: 1px solid transparent; /* Add transparent border */
}

.schedule-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(79, 99, 112, 1);
  fill: #0EA288;
  border: 1px solid #0EA288; /* Change border color on hover */
}

.schedule-icon-button .icon {
  filter: none; /* Ensure the icon is displayed correctly by default */
}

.schedule-icon-button:hover .icon {
  filter: invert(42%) sepia(78%) saturate(676%) hue-rotate(126deg) brightness(89%) contrast(85%);
}
/* solution css */
.card:hover .card-icon {
  filter: brightness(0) invert(1);
}

.card:hover .id-circle {
  background-color: #FFFFFF;
  color: #0EA288;
}

.card-title {
  color: #0EA288;
  transition: color 0.3s ease-in-out;
}

.card:hover .card-title {
  color: #FFFFFF;
}

/* carousel */

@layer utilities {
  .animation-pause {
    animation-play-state: paused;
  }
}

